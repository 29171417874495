.top-banner {
  position: relative;
  background: #f2f3f4;

  &::before {
    content: "";
    background: url("/src/images/young-man-grey-t-shirt-counting-euro-banknotes-min.jpg");
    background-size: cover;
    background-position: top center;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 330px;
    z-index: 1;

    @include md {
      height: 100%;
    }
  }

  &__title {
    margin: 43px 0 20px;
    font: 700 20px/33px $poppins;
    max-width: 450px;

    @include md {
      margin: 93px 0 33px;
      font: 700 46px/70px $poppins;
      max-width: 550px;
    }
  }

  .container {
    position: inherit;
    z-index: 2;
  }

  .price-label {
    display: flex;
    width: fit-content;
    background-color: $color-primary;
    margin: 15px 0 13px;
    padding: 10px 24px;
    font: 800 italic 28px/43px $poppins;
    letter-spacing: 1px;
    color: #fff;
    border-radius: 25px;

    @include md {
      margin: 31px 0 19px;
      padding: 16px 32px;
      font: 700 56px/70px $poppins;
    }
  }

  .btn-wrapper {
    padding-top: 18px;

    @include xxs {
      padding-top: 10px;
    }

    @include sm {
      padding-top: 4px;
    }

    @include md {
      display: flex;
      flex-direction: row-reverse;
      gap: 40px;
    }
  }

  .btn {
    margin: 0 0 26px;

    @include md {
      margin: 0 0 32px;
    }
  }

  .certification {
    &-wrapper {
      display: flex;
      justify-content: space-between;
      gap: 13px;
      margin: 0 0 32px;
      transform: scale(0.8);

      @include xxs {
        transform: scale(1);
      }

      @include md {
        gap: 46px;
      }
    }

    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 110px;
      height: 67px;
      background-color: $color-white;
      border-radius: 15px;

      @include md {
        width: 180px;
        height: 110px;
        border-radius: 25px;
      }

      img {
        object-fit: contain;
        max-width: 72%;
      }
    }
  }

  .add-info {
    display: flex;
    width: 100%;
    margin: 0 0 22px;
    padding: 16px;
    max-width: 450px;

    background-color: $color-white;
    border-radius: 25px;

    @include md {
      max-width: 54.817%;
    }

    p {
      margin: 0;

      color: $color-primary;
      font: 700 12px/20px $poppins;

      @include md {
        font: 600 18px/24px $poppins;
        margin-left: 12px;
        text-indent: -12px;
      }
    }
  }
}
