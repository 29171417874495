.recover-summ {
  display: none;
  padding: 69px 0 0;

  &.show {
    display: block;
  }

  @include md {
    padding: 129px 0 0;
  }

  .container {
    @include md {
      align-items: center;
    }

    &::after {
      @include md {
        content: "";
        width: 100%;
        height: 1px;
        background-color: #d4d4d4;
        margin: 111px 0 0;
      }
    }
  }

  p {
    font: 500 18px/28px $poppins;
    text-align: center;
    margin: 32px 0 21px;

    @include md {
      margin: 49px 0 21px;
      font: 500 24px/40px $poppins;
      max-width: 482px;
    }
  }

  &__value {
    font: 700 48px/42px $poppins;
    color: $color-primary;
    margin: 0 0 32px;

    @include md {
      font: 700 56px/70px $poppins;
      margin: 0 0 111px;
    }
  }
}
