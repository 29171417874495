.services-cost {
  padding: 48px 0;

  @include md {
    padding: 66px 0 44px;
  }

  h3 {
    margin: 0 0 31px;

    @include md {
      width: 100%;
      margin: 0 0 62px;
    }
  }

  .plan-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 48px;
    margin: 0 0 59px;

    @include md {
      flex-direction: row;
      align-items: flex-start;
      margin: 0 0 80px;
    }

    @include lg {
      gap: 63px;
    }

    .plan {
      width: 100%;
      max-width: 400px;

      .header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 14px;
        gap: 10px;

        background-color: $color-black;

        @include md {
          padding: 16px;
        }

        h4 {
          font: 600 20px/30px $poppins;
          color: $color-white;
          text-align: center;
          margin: 0;

          @include md {
            font: 600 26px/39px $poppins;
          }
        }

        p {
          font: 400 14px/21px $poppins;
          color: $color-white;
          text-align: center;
          margin: 0;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        background-color: #f0f0f0;
        padding: 21px;
        gap: 21px;

        @include lg {
          padding: 24px;
          gap: 24px;
        }

        ul {
          display: flex;
          flex-direction: column;
          margin: 0;
          gap: 21px;

          @include md {
            padding: 0 0 0 20px;
          }

          @include lg {
            gap: 24px;
          }
        }

        li {
          font: 600 15px/26px $poppins;
          color: $color-black;

          @include md {
            font: 600 18px/30px $poppins;
          }
        }

        .plan-price {
          font: 800 26px/26px $poppins;
          color: $color-black;
          text-align: center;

          @include md {
            font: 800 30px/30px $poppins;
          }
        }

        .btn {
          justify-content: center;
          font: 600 16px/24px $poppins;

          @include md {
            font: 600 18px/27px $poppins;
            width: 100%;
          }
        }
      }

      &.premium {
        position: relative;

        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 93%;
          left: -9px;
          bottom: -9px;
          z-index: -1;
          background-color: $color-green;
        }

        .header {
          background-color: $color-primary;

          @include md {
            h4 {
              font: 600 36px/54px $poppins;
            }
          }
        }

        .content {
          .plan-price {
            @include md {
              font: 800 36px/30px $poppins;
            }
          }

          .btn {
            @include md {
              font: 600 20px/30px $poppins;
            }
          }
        }
      }

      &.vip {
        position: relative;

        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 93%;
          left: -9px;
          bottom: -9px;
          z-index: -1;
          background-color: $color-black;
        }

        .header {
          background-color: $color-yellow;

          @include md {
            h4 {
              font: 700 36px/54px $poppins;
            }
          }

          h4,
          p {
            color: $color-black;
          }
        }

        .content {
          .plan-price {
            @include md {
              font: 800 36px/30px $poppins;
            }
          }

          .btn {
            @include md {
              font: 700 20px/30px $poppins;
            }

            img {
              filter: brightness(0);
            }
          }
        }
      }
    }
  }

  .card {
    background-color: #f1f1f1;
    border-radius: 20px;
    max-width: 550px;

    @include md {
      display: flex;
      justify-content: space-between;
      width: 100%;
      border-radius: 25px;
      max-width: unset;
    }

    > img {
      width: 100%;

      @include md {
        max-width: 550px;
      }

      @include lg {
        max-width: 693px;
      }
    }

    .card-content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 24px;

      @include md {
        padding: 40px;
        gap: 20px;
      }

      @include lg {
        padding: 68px 101px 71px 86px;
        gap: 24px;
        justify-content: space-around;
      }

      h3 {
        font: 700 20px/30px $poppins;
        columns: $color-black;
        text-align: left;
        margin: 0;

        @include md {
          font: 700 28px/47px $poppins;
        }

        @include lg {
          font: 700 36px/67px $poppins;
        }
      }

      p {
        font: 500 16px/28px $poppins;
        color: $color-black;
        text-emphasis: left;
        margin: 0;

        @include md {
          font: 500 18px/29px $poppins;
        }

        @include lg {
          font: 500 20px/30px $poppins;
        }
      }

      .btn {
        font: 700 16px/24px $poppins;
        padding: 15px 40px;
        white-space: nowrap;

        @include md {
          font: 700 20px/30px $poppins;
          padding: 19px 40px;
        }
      }
    }
  }
}
