.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 14px;
  gap: 10px;
  max-width: 350px;
  margin: 0 auto;

  font: 600 12px/16px $poppins;
  color: #fff;
  text-decoration: none;

  border-radius: 48px;
  cursor: pointer;

  @include xxs {
    padding: 13px 24px;
    font: 600 18px/27px $poppins;
  }

  @include sm {
    padding: 16px 24px;
    font: 600 18px/27px $poppins;
  }

  @include md {
    width: fit-content;
    max-width: unset;
    margin: initial;
  }

  &--blue {
    background-color: $color-primary;
  }

  &--black {
    background-color: $color-black;
  }

  &--white {
    background-color: $color-white;
    color: $color-primary;
    width: fit-content;
    margin: 0;
  }

  &--yellow {
    background-color: $color-yellow;
    color: $color-black;
  }

  &--green {
    background-color: $color-green;
    color: $color-black;
    justify-content: center;

    img {
      filter: brightness(0);
    }
  }
}
