$poppins: "Poppins", sans-serif;
$color-primary: #151ccd;
$color-black: #000;
$color-white: #fff;
$color-gray: #edeeef;
$color-yellow: #ffd600;
$color-green: #c0ff00;
$gradient-gray: linear-gradient(
    270deg,
    #f2f3f4 -3.51%,
    rgba(242, 243, 244, 0) 29.81%
  ),
  #edeeef;
