.clients-feedback {
  padding: 0 0 38px;
  position: relative;

  @include md {
    padding: 0 0 58px;
  }

  .desktop-container {
    @include md {
      position: relative;
    }
  }

  h3 {
    margin: 0 30px 42px;

    @include md {
      width: 100%;
      margin: 0 0 82px;
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    max-width: 90%;
    margin: 0 auto;

    @include md {
      max-width: 353px;
    }

    .image {
      position: relative;
      user-select: none;
      pointer-events: none;
      padding: 10px 0;

      &::after {
        content: "";
        width: 180px;
        height: 160px;
        z-index: -1;
        background-color: #151ccd;
        display: flex;
        position: absolute;
        top: 21px;
        left: -12px;
        transform: skewX(-3deg) rotate(2deg);
      }
    }

    .img-wrapper {
      width: 180px;
      height: 160px;
      overflow: hidden;
      transform: skewX(-3deg) rotate(2deg);

      img {
        object-fit: cover;
        width: 120%;
        height: 120%;
        position: relative;
        top: 50%;
        left: 52%;
        transform: skewX(3deg) translate(-50%, -50%);
      }
    }

    img {
      max-width: 212px;
      position: relative;
    }

    .text p {
      font: 600 18px/30px $poppins;
      color: $color-black;
      text-align: center;
      margin: 0;
      max-width: 450px;
    }

    .name p {
      font: 400 15px/28px $poppins;
      color: $color-black;
      text-align: center;
      margin: 0;
    }

    .customNextBtn {
      width: 40px;
      position: absolute;
      top: 50%;
      right: -60px;
    }
  }

  .customPrevBtn,
  .customNextBtn {
    cursor: pointer;
    position: absolute;
    top: 36%;
    transform: translateY(-50%);
    z-index: 11;
    width: 40px;
    height: 40px;
    user-select: none;

    @include md {
      top: 57%;
    }
  }

  .customPrevBtn {
    left: 20px;

    @include xxl {
      left: -20px;
    }
  }

  .customNextBtn {
    right: 20px;

    @include xxs {
      right: 15%;
    }

    @include xs {
      right: 20%;
    }

    @include md {
      right: 20px;
    }

    @include xxl {
      right: -20px;
    }
  }

  .owl-theme .owl-nav.disabled + .owl-dots {
    margin: 0;
  }

  .owl-theme .owl-dots .owl-dot span {
    width: 32px;
    height: 4px;
    border-radius: 0;
    background-color: $color-black;
  }

  .owl-theme .owl-dots:focus .owl-dot span {
    background-color: $color-black;
  }

  .owl-theme .owl-dots .owl-dot.active span,
  .owl-theme .owl-dots .owl-dot:hover span {
    background-color: $color-primary;
  }
}
