footer {
  width: 100%;
  background-color: #f1f1f1;
  padding: 47px 0 26px;

  @include md {
    padding: 55px 0 50px;
  }

  .container {
    padding: 0 22px 0 35px;
  }

  .top {
    width: 100%;

    @include md {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 16px;
    }

    .title-1 {
      text-align: left;
      margin: 0 0 18px;

      @include md {
        font: 700 24px/36px $poppins;
      }
    }

    .btn-wrapper {
      @include md {
        display: flex;
        gap: 20px;
      }

      @include lg {
        gap: 40px;
      }
    }

    .btn {
      margin: 0 0 32px;
      white-space: nowrap;

      @include md {
        margin: 0;
      }
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 7px 0 0;

    @include md {
      padding: 0;
      margin: 0 0 32px;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      flex-wrap: wrap;
      row-gap: 30px;
    }

    .logo-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 32px;
      margin: 0 0 20px;

      @include md {
        gap: 29px;
        margin: 0;
      }

      img {
        height: 28px;
      }

      .copyright {
        margin: 0;
        font: 500 14px/21px $poppins;
        color: $color-black;
      }
    }

    .static-pages {
      margin: 0 0 20px;

      @include md {
        margin: 0;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        display: flex;
        flex-direction: column;
        gap: 24px;

        @include md {
          flex-direction: row;
          gap: 32px;
        }
      }

      li a {
        text-decoration: underline;
        font: 500 14px/21px $poppins;
        color: $color-black;
      }
    }

    .payment-methods {
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      gap: 22px;
      display: flex;
      padding: 20px 0 0px;

      @include md {
        gap: 32px;
        padding: 0;
      }

      img {
        &:nth-child(1) {
          max-height: 26px;

          @include md {
            max-height: 22px;
          }
        }

        &:nth-child(2) {
          max-height: 48px;

          @include md {
            max-height: 36px;
          }
        }

        &:nth-child(3) {
          max-height: 36px;

          @include md {
            max-height: 24px;
          }
        }
      }
    }
  }

  .sub-footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 35px 0 0;
    gap: 27px;

    @include md {
      margin: 0;
      gap: 24px;
    }

    .follow-us {
      font: 500 14px/21px $poppins;
      color: $color-black;
    }

    .social-icons {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 36px;

      @include md {
        gap: 32px;
      }

      a {
        text-decoration: none;
        display: flex;

        &:last-child {
          img {
            max-height: 25px;
            height: 25px;

            @include md {
              max-height: 30px;
              height: 30px;
            }
          }
        }
      }

      img {
        max-height: 27px;
        width: auto;
        height: 27px;

        @include md {
          max-height: 32px;
          height: 32px;
        }
      }
    }
  }
}
