.video-feedback {
  background-color: #f1f1f1;
  padding: 34px 0 48px;
  position: relative;

  @include md {
    padding: 71px 0 89px;
  }

  .desktop-container {
    position: relative;
  }

  h3 {
    margin: 0 0 48px;

    @include md {
      width: 100%;
      margin: 0 0 64px;
      font: 700 30px/45px $poppins;
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    transform: translateX(30px);
    padding-inline: 15px;

    @include xxs {
      transform: translateX(0);
      padding-inline: 0;
    }

    @include md {
      gap: 30px;
      margin-left: 60px;
    }

    .video {
      position: relative;
      width: 300px;
      height: 168px;
      overflow: hidden;
      box-shadow: -9px 9px $color-primary;

      @include md {
        width: 350px;
        height: 218px;
      }

      @include lg {
        width: 480px;
        height: 270px;
      }

      .video-preview {
        filter: brightness(50%);
        z-index: 1;
        display: flex;
        position: inherit;
        top: 50%;
        transform: translateY(-50%);
        min-height: 100%;
        min-width: 100%;
      }

      .play-video {
        position: absolute;
        display: flex;
        width: 46px;
        height: 46px;
        z-index: 2;
        transform: translate(-50%, -50%);
        top: 53%;
        left: 50%;
        cursor: pointer;

        @include md {
          width: 60px;
          height: 60px;
        }
      }

      .video-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color-primary;
        opacity: 0.25;
        cursor: pointer;
        transform: initial;
      }
    }

    .text p {
      font: 600 18px/30px $poppins;
      color: $color-black;
      text-align: center;
      margin: 0;
    }

    .name p {
      font: 400 15px/28px $poppins;
      color: $color-black;
      text-align: center;
      margin: 0;
    }

    .text p,
    .name p {
      max-width: 353px;
    }
  }

  .video iframe {
    position: absolute;
    top: 0;
  }

  .customVideoPrevBtn,
  .customVideoNextBtn {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 11;
    width: 40px;
    height: 40px;
    user-select: none;
  }

  .customVideoPrevBtn {
    left: 20px;

    @include xxl {
      left: -20px;
    }
  }

  .customVideoNextBtn {
    right: 20px;

    @include xxl {
      right: -20px;
    }
  }

  .carousel-fixed-width {
    width: 300px !important;
  }

  .owl-theme .owl-nav.disabled + .owl-dots {
    margin: 60px 0 0;
  }

  .owl-theme .owl-dots .owl-dot span {
    width: 32px;
    height: 4px;
    border-radius: 0;
    background-color: $color-black;
  }

  .owl-theme .owl-dots:focus .owl-dot span {
    background-color: $color-black;
  }

  .owl-theme .owl-dots .owl-dot.active span,
  .owl-theme .owl-dots .owl-dot:hover span {
    background-color: $color-primary;
  }

  .owl-stage {
    left: -30;
  }
}
