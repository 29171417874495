.slider-form {
  background-color: $color-gray;
  padding: 0 0 51px;

  @include md {
    padding: 69px 0 115px;
  }

  .container {
    @include md {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 40px;
    }

    @include lg {
      gap: 67px;
    }
  }

  .text-wrapper {
    max-width: 550px;

    @include md {
      display: flex;
      flex-direction: column;
      max-width: 588px;
      flex: 1;
    }
  }

  h2 {
    margin: 21px 0 16px;
    text-align: center;
    font: 700 26px/36px $poppins;
    letter-spacing: 0.5px;
    color: $color-black;

    @include md {
      font: 700 35px/46px $poppins;
      margin: 0 0 38px;
      text-align: left;
    }

    @include lg {
      font: 700 44px/56px $poppins;
    }
  }

  p {
    font: 400 17px/30px $poppins;
    text-align: center;
    color: $color-black;
    margin: 0 0 44px;

    @include md {
      font: 500 20px/33px $poppins;
      text-align: left;
    }

    @include lg {
      font: 500 24px/36px $poppins;
    }
  }

  form {
    @include md {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
    }

    h3 {
      font: 700 20px/30px $poppins;
      margin: 0 0 16px;

      @include md {
        font: 700 24px/36px $poppins;
        margin: 0 0 8px;
      }

      &.form-title {
        text-align: center;

        @include md {
          margin: 0 0 16px;
        }
      }
    }
  }

  .tiles-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 500px;

    gap: 24px 41px;
    margin: 0 0 16px;

    @include md {
      max-width: initial;
      justify-content: space-between;
      flex-wrap: nowrap;

      gap: 24px;
      margin: 0 0 25px;
    }

    @include lg {
      justify-content: flex-start;
      gap: 24px 37px;
    }

    .tile {
      display: flex;
      width: fit-content;
      margin: 0;
      position: relative;

      .checkbox,
      img {
        display: none;
      }

      img {
        position: absolute;
        top: 2px;
        right: 4px;
      }

      h4 {
        width: calc((82vw - 41px) / 2);
        display: flex;
        justify-content: center;
        background-color: $color-white;
        border: 2px solid #c2c2c2;
        margin: 0;
        padding: 10px 0;

        font: 500 18px/27px $poppins;
        letter-spacing: 0.5px;
        color: $color-black;

        cursor: pointer;

        @include xxs {
          width: 158px;
        }

        @include md {
          width: 100px;
        }

        @include lg {
          width: 130px;
        }
      }

      input:checked + label {
        h4 {
          background-color: $color-primary;
          color: $color-white;
          border-color: $color-black;
          font-weight: 600;
        }

        img {
          display: flex;
        }
      }
    }
  }

  input[type="range"] {
    width: 100%;
  }

  input[type="range"] {
    color: #151bcd;
    --thumb-height: 43px;
    --thumb-width: 22px;
    --track-height: 4px;
    --track-color: currentColor;
    --brightness-hover: 180%;
    --brightness-down: 80%;
    --clip-edges: 0.0125em;
  }

  /* === range commons === */
  input[type="range"] {
    position: relative;
    background: #fff0;
    overflow: hidden;
  }

  input[type="range"]:active {
    cursor: grabbing;
  }

  input[type="range"]:disabled {
    filter: grayscale(1);
    opacity: 0.3;
    cursor: not-allowed;
  }

  /* === WebKit specific styles === */
  input[type="range"],
  input[type="range"]::-webkit-slider-runnable-track,
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    transition: all ease 100ms;
    height: var(--thumb-height);
  }

  input[type="range"]::-webkit-slider-runnable-track,
  input[type="range"]::-webkit-slider-thumb {
    position: relative;
  }

  input[type="range"]::-webkit-slider-thumb {
    --thumb-radius: calc((var(--thumb-height) * 0.5) - 1px);
    --clip-top: calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px);
    --clip-bottom: calc(var(--thumb-height) - var(--clip-top));
    --clip-further: calc(100% + 1px);
    --box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0
      100vmax #000;

    width: var(--thumb-width, var(--thumb-height));
    background: linear-gradient(currentColor 0 0) scroll no-repeat left center /
      50% calc(var(--track-height) + 1px);
    background: url("../images/icons/pause.svg"), currentColor;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: var(--box-fill);
    border: 2px solid #000;

    filter: brightness(100%);
    clip-path: polygon(
      100% -1px,
      var(--clip-edges) -1px,
      0 var(--clip-top),
      -100vmax var(--clip-top),
      -100vmax var(--clip-bottom),
      0 var(--clip-bottom),
      var(--clip-edges) 100%,
      var(--clip-further) var(--clip-further)
    );
  }

  input[type="range"]:hover::-webkit-slider-thumb {
    filter: brightness(var(--brightness-hover));
    cursor: grab;
  }

  input[type="range"]:active::-webkit-slider-thumb {
    filter: brightness(var(--brightness-down));
    cursor: grabbing;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    background: linear-gradient(var(--track-color) 0 0) scroll no-repeat center /
      100% calc(var(--track-height) + 1px);
  }

  input[type="range"]:disabled::-webkit-slider-thumb {
    cursor: not-allowed;
  }

  /* === Firefox specific styles === */
  input[type="range"],
  input[type="range"]::-moz-range-track,
  input[type="range"]::-moz-range-thumb {
    appearance: none;
    transition: all ease 100ms;
    height: var(--thumb-height);
  }

  input[type="range"]::-moz-range-track,
  input[type="range"]::-moz-range-thumb,
  input[type="range"]::-moz-range-progress {
    background: #fff0;
  }

  input[type="range"]::-moz-range-thumb {
    background: $color-primary;
    border: 0;
    width: var(--thumb-width, var(--thumb-height));
    border-radius: var(--thumb-width, var(--thumb-height));
    cursor: grab;
  }

  input[type="range"]:active::-moz-range-thumb {
    cursor: grabbing;
  }

  input[type="range"]::-moz-range-track {
    width: 100%;
    background: var(--track-color);
  }

  input[type="range"]::-moz-range-progress {
    appearance: none;
    background: currentColor;
    transition-delay: 30ms;
  }

  input[type="range"]::-moz-range-track,
  input[type="range"]::-moz-range-progress {
    height: calc(var(--track-height) + 1px);
    border-radius: var(--track-height);
  }

  input[type="range"]::-moz-range-thumb,
  input[type="range"]::-moz-range-progress {
    filter: brightness(100%);
  }

  input[type="range"]:hover::-moz-range-thumb,
  input[type="range"]:hover::-moz-range-progress {
    filter: brightness(var(--brightness-hover));
  }

  input[type="range"]:active::-moz-range-thumb,
  input[type="range"]:active::-moz-range-progress {
    filter: brightness(var(--brightness-down));
  }

  input[type="range"]:disabled::-moz-range-thumb {
    cursor: not-allowed;
  }

  .money-range-label,
  .month-range-label {
    background-color: $color-primary;
    font: 700 20px/30px $poppins;
    color: $color-white;

    width: fit-content;
    padding: 5px 14px;

    @include md {
      padding: 10px 22px;
    }
  }

  .money-range-label {
    @include md {
      font: 700 25px/38px $poppins;
    }
  }

  .form-submit {
    margin: 16px auto 0;
    border: none;
    cursor: pointer;

    @include md {
      margin: 32px 0 0;
      background-color: $color-primary;
      align-self: center;
    }
  }

  .calculator-wrapper {
    overflow: hidden;
    position: relative;
    display: flex;
    flex-wrap: nowrap;

    @include md {
      padding: 48px;
      background-color: $color-white;
      box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
    }
  }

  .calculator-form {
    position: relative;
    left: 0;
    transition: all 0.15s ease-in-out;
  }

  .calculator-result {
    position: absolute;
    top: 0;
    left: 100%;
    transition: all 0.15s ease-in-out;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    @include md {
      justify-content: center;
    }

    h3 {
      margin: 0 0 16px;
      font: 700 24px / 36px $poppins;

      @include md {
        margin: 0 0 32px;
        font: 700 44px / 56px $poppins;
      }
    }

    p {
      margin: 0 0 32px;
      font: 700 38px / 42px $poppins;
      color: $color-primary;

      @include md {
        font: 700 56px / 70px $poppins;
      }
    }

    button {
      border: 0;
      img {
        transform: rotate(90deg);
      }
    }
  }

  .show-result {
    .calculator-form {
      left: calc(-100% - 96px);
    }

    .calculator-result {
      left: 0;
    }
  }
}
