*, :before, :after {
  box-sizing: border-box;
}

body {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  font-family: Poppins, sans-serif;
  display: flex;
  overflow-x: hidden;
}

body:has(.nav-list.open) .top-nav .nav-container {
  position: fixed;
  top: 0;
}

body:has(.nav-list.open) main {
  margin-top: 99px;
}

main {
  width: 100%;
}

.display-mobile {
  display: flex !important;
}

@media (min-width: 1024px) {
  .display-mobile {
    display: none !important;
  }
}

.display-desktop {
  display: none !important;
}

@media (min-width: 1024px) {
  .display-desktop {
    display: flex !important;
  }
}

.display-desktop-lg {
  display: none !important;
}

@media (min-width: 1290px) {
  .display-desktop-lg {
    display: flex !important;
  }
}

.display-none {
  display: none !important;
}

.container {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 28px 0 29px;
  display: flex;
}

@media (min-width: 1024px) {
  .container {
    max-width: 1564px;
    align-items: flex-start;
    margin: 0 auto;
    padding: 0 30px;
  }

  .desktop-container {
    width: 100%;
    max-width: 1564px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0 auto;
    padding: 0 30px;
    display: flex;
  }
}

.title-1 {
  color: #000;
  text-align: center;
  margin: 0;
  font: 700 24px / 36px Poppins, sans-serif;
}

@media (min-width: 1024px) {
  .title-1 {
    font: 700 44px / 56px Poppins, sans-serif;
  }
}

.title-2 {
  color: #000;
  text-align: center;
  margin: 0;
  font: 700 28px / 42px Poppins, sans-serif;
}

@media (min-width: 1024px) {
  .title-2 {
    font: 700 44px / 66px Poppins, sans-serif;
  }
}

.top-nav {
  width: 100%;
  z-index: 11;
  display: flex;
}

@media (min-width: 1024px) {
  .top-nav {
    justify-content: space-between;
    align-items: center;
    padding: 17px 0 19px;
    box-shadow: 0 6px 8px #0000002e;
  }
}

.top-nav .container {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

@media (min-width: 1024px) {
  .top-nav .container {
    gap: 30px;
    padding: 0 30px;
  }
}

.top-nav .nav-container {
  width: 100%;
  z-index: 10;
  background-color: #fff;
  justify-content: space-between;
  padding: 20px 40px 18px 25px;
  display: flex;
  box-shadow: 0 6px 8px #0000002e;
}

@media (min-width: 1024px) {
  .top-nav .nav-container {
    width: -moz-fit-content;
    width: fit-content;
    box-shadow: unset;
    padding: 0;
  }
}

.top-nav .logo {
  text-decoration: none;
}

.top-nav .menu-icon {
  display: flex;
}

.top-nav .menu-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (min-width: 1024px) {
  .top-nav .menu-wrapper {
    gap: 30px;
  }
}

@media (min-width: 1440px) {
  .top-nav .menu-wrapper {
    gap: 50px;
  }
}

.top-nav .nav-list {
  height: 100vh;
  height: 100dvh;
  z-index: 1;
  background-color: #fff;
  flex-direction: column;
  margin: 0;
  padding: 112px 45px 0 25px;
  list-style: none;
  transition: all .15s ease-in-out;
  display: flex;
  position: fixed;
  top: -200vh;
  left: 0;
  right: 0;
  overflow: auto;
  box-shadow: 0 6px 8px #0000002e;
}

@media (min-width: 1024px) {
  .top-nav .nav-list {
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: initial;
    box-shadow: unset;
    overflow: initial;
    flex-direction: row;
    gap: 18px;
    padding: 0;
  }
}

@media (min-width: 1440px) {
  .top-nav .nav-list {
    gap: 28px;
  }
}

.top-nav .nav-list.open {
  top: 0;
}

@media (min-width: 1024px) {
  .top-nav .nav-list li {
    padding: 0 5px;
  }
}

.top-nav .nav-list li:not(:last-child) {
  border-bottom: 1px solid #151ccd;
}

@media (min-width: 1024px) {
  .top-nav .nav-list li:not(:last-child) {
    border: none;
  }
}

.top-nav .nav-list li a {
  color: #000;
  white-space: nowrap;
  cursor: pointer;
  font: 700 19px / 76px Poppins, sans-serif;
  text-decoration: none;
}

@media (min-width: 1024px) {
  .top-nav .nav-list li a {
    font: 500 16px / 24px Poppins, sans-serif;
    position: relative;
  }

  .top-nav .nav-list li a:after {
    content: "";
    width: 0;
    height: 4px;
    background-color: #151ccd;
    transition: width .15s ease-in-out, left .15s ease-in-out;
    position: absolute;
    bottom: -9px;
    left: 0;
    right: 0;
  }
}

.top-nav .nav-list li a.tel {
  font-weight: 700;
}

@media (min-width: 1024px) {
  .top-nav .nav-list li a.tel {
    display: none;
  }
}

@media (min-width: 1290px) {
  .top-nav .nav-list li a.tel {
    display: initial;
  }
}

.top-nav .nav-list li a.active {
  font-weight: 700;
}

@media (min-width: 1024px) {
  .top-nav .nav-list li a.active:after {
    width: 100%;
    left: 0;
  }
}

.top-nav .btn {
  width: -moz-fit-content;
  width: fit-content;
  white-space: nowrap;
  font: 600 16px / 24px Poppins, sans-serif;
}

.top-nav .menu-button {
  display: none;
}

@media (min-width: 1700px) {
  .top-nav .menu-button {
    display: flex;
  }
}

.btn {
  width: 100%;
  max-width: 350px;
  color: #fff;
  cursor: pointer;
  border-radius: 48px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 0 auto;
  padding: 10px 14px;
  font: 600 12px / 16px Poppins, sans-serif;
  text-decoration: none;
  display: flex;
}

@media (min-width: 414px) {
  .btn {
    padding: 13px 24px;
    font: 600 18px / 27px Poppins, sans-serif;
  }
}

@media (min-width: 768px) {
  .btn {
    padding: 16px 24px;
    font: 600 18px / 27px Poppins, sans-serif;
  }
}

@media (min-width: 1024px) {
  .btn {
    width: -moz-fit-content;
    width: fit-content;
    max-width: unset;
    margin: initial;
  }
}

.btn--blue {
  background-color: #151ccd;
}

.btn--black {
  background-color: #000;
}

.btn--white {
  color: #151ccd;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #fff;
  margin: 0;
}

.btn--yellow {
  color: #000;
  background-color: #ffd600;
}

.btn--green {
  color: #000;
  background-color: #c0ff00;
  justify-content: center;
}

.btn--green img {
  filter: brightness(0);
}

.top-banner {
  background: #f2f3f4;
  position: relative;
}

.top-banner:before {
  content: "";
  width: 100%;
  height: 330px;
  z-index: 1;
  background: url("young-man-grey-t-shirt-counting-euro-banknotes-min.2441fb17.jpg") top / cover;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 1024px) {
  .top-banner:before {
    height: 100%;
  }
}

.top-banner__title {
  max-width: 450px;
  margin: 43px 0 20px;
  font: 700 20px / 33px Poppins, sans-serif;
}

@media (min-width: 1024px) {
  .top-banner__title {
    max-width: 550px;
    margin: 93px 0 33px;
    font: 700 46px / 70px Poppins, sans-serif;
  }
}

.top-banner .container {
  position: inherit;
  z-index: 2;
}

.top-banner .price-label {
  width: -moz-fit-content;
  width: fit-content;
  letter-spacing: 1px;
  color: #fff;
  background-color: #151ccd;
  border-radius: 25px;
  margin: 15px 0 13px;
  padding: 10px 24px;
  font: italic 800 28px / 43px Poppins, sans-serif;
  display: flex;
}

@media (min-width: 1024px) {
  .top-banner .price-label {
    margin: 31px 0 19px;
    padding: 16px 32px;
    font: 700 56px / 70px Poppins, sans-serif;
  }
}

.top-banner .btn-wrapper {
  padding-top: 18px;
}

@media (min-width: 414px) {
  .top-banner .btn-wrapper {
    padding-top: 10px;
  }
}

@media (min-width: 768px) {
  .top-banner .btn-wrapper {
    padding-top: 4px;
  }
}

@media (min-width: 1024px) {
  .top-banner .btn-wrapper {
    flex-direction: row-reverse;
    gap: 40px;
    display: flex;
  }
}

.top-banner .btn {
  margin: 0 0 26px;
}

@media (min-width: 1024px) {
  .top-banner .btn {
    margin: 0 0 32px;
  }
}

.top-banner .certification-wrapper {
  justify-content: space-between;
  gap: 13px;
  margin: 0 0 32px;
  display: flex;
  transform: scale(.8);
}

@media (min-width: 414px) {
  .top-banner .certification-wrapper {
    transform: scale(1);
  }
}

@media (min-width: 1024px) {
  .top-banner .certification-wrapper {
    gap: 46px;
  }
}

.top-banner .certification-item {
  width: 110px;
  height: 67px;
  background-color: #fff;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (min-width: 1024px) {
  .top-banner .certification-item {
    width: 180px;
    height: 110px;
    border-radius: 25px;
  }
}

.top-banner .certification-item img {
  object-fit: contain;
  max-width: 72%;
}

.top-banner .add-info {
  width: 100%;
  max-width: 450px;
  background-color: #fff;
  border-radius: 25px;
  margin: 0 0 22px;
  padding: 16px;
  display: flex;
}

@media (min-width: 1024px) {
  .top-banner .add-info {
    max-width: 54.817%;
  }
}

.top-banner .add-info p {
  color: #151ccd;
  margin: 0;
  font: 700 12px / 20px Poppins, sans-serif;
}

@media (min-width: 1024px) {
  .top-banner .add-info p {
    text-indent: -12px;
    margin-left: 12px;
    font: 600 18px / 24px Poppins, sans-serif;
  }
}

.slider-form {
  background-color: #edeeef;
  padding: 0 0 51px;
}

@media (min-width: 1024px) {
  .slider-form {
    padding: 69px 0 115px;
  }

  .slider-form .container {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }
}

@media (min-width: 1290px) {
  .slider-form .container {
    gap: 67px;
  }
}

.slider-form .text-wrapper {
  max-width: 550px;
}

@media (min-width: 1024px) {
  .slider-form .text-wrapper {
    max-width: 588px;
    flex-direction: column;
    flex: 1;
    display: flex;
  }
}

.slider-form h2 {
  text-align: center;
  letter-spacing: .5px;
  color: #000;
  margin: 21px 0 16px;
  font: 700 26px / 36px Poppins, sans-serif;
}

@media (min-width: 1024px) {
  .slider-form h2 {
    text-align: left;
    margin: 0 0 38px;
    font: 700 35px / 46px Poppins, sans-serif;
  }
}

@media (min-width: 1290px) {
  .slider-form h2 {
    font: 700 44px / 56px Poppins, sans-serif;
  }
}

.slider-form p {
  text-align: center;
  color: #000;
  margin: 0 0 44px;
  font: 400 17px / 30px Poppins, sans-serif;
}

@media (min-width: 1024px) {
  .slider-form p {
    text-align: left;
    font: 500 20px / 33px Poppins, sans-serif;
  }
}

@media (min-width: 1290px) {
  .slider-form p {
    font: 500 24px / 36px Poppins, sans-serif;
  }
}

@media (min-width: 1024px) {
  .slider-form form {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
  }
}

.slider-form form h3 {
  margin: 0 0 16px;
  font: 700 20px / 30px Poppins, sans-serif;
}

@media (min-width: 1024px) {
  .slider-form form h3 {
    margin: 0 0 8px;
    font: 700 24px / 36px Poppins, sans-serif;
  }
}

.slider-form form h3.form-title {
  text-align: center;
}

@media (min-width: 1024px) {
  .slider-form form h3.form-title {
    margin: 0 0 16px;
  }
}

.slider-form .tiles-wrapper {
  max-width: 500px;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px 41px;
  margin: 0 0 16px;
  display: flex;
}

@media (min-width: 1024px) {
  .slider-form .tiles-wrapper {
    max-width: initial;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 24px;
    margin: 0 0 25px;
  }
}

@media (min-width: 1290px) {
  .slider-form .tiles-wrapper {
    justify-content: flex-start;
    gap: 24px 37px;
  }
}

.slider-form .tiles-wrapper .tile {
  width: -moz-fit-content;
  width: fit-content;
  margin: 0;
  display: flex;
  position: relative;
}

.slider-form .tiles-wrapper .tile .checkbox, .slider-form .tiles-wrapper .tile img {
  display: none;
}

.slider-form .tiles-wrapper .tile img {
  position: absolute;
  top: 2px;
  right: 4px;
}

.slider-form .tiles-wrapper .tile h4 {
  width: calc(41vw - 20.5px);
  letter-spacing: .5px;
  color: #000;
  cursor: pointer;
  background-color: #fff;
  border: 2px solid #c2c2c2;
  justify-content: center;
  margin: 0;
  padding: 10px 0;
  font: 500 18px / 27px Poppins, sans-serif;
  display: flex;
}

@media (min-width: 414px) {
  .slider-form .tiles-wrapper .tile h4 {
    width: 158px;
  }
}

@media (min-width: 1024px) {
  .slider-form .tiles-wrapper .tile h4 {
    width: 100px;
  }
}

@media (min-width: 1290px) {
  .slider-form .tiles-wrapper .tile h4 {
    width: 130px;
  }
}

.slider-form .tiles-wrapper .tile input:checked + label h4 {
  color: #fff;
  background-color: #151ccd;
  border-color: #000;
  font-weight: 600;
}

.slider-form .tiles-wrapper .tile input:checked + label img {
  display: flex;
}

.slider-form input[type="range"] {
  width: 100%;
  color: #151bcd;
  --thumb-height: 43px;
  --thumb-width: 22px;
  --track-height: 4px;
  --track-color: currentColor;
  --brightness-hover: 180%;
  --brightness-down: 80%;
  --clip-edges: .0125em;
  background: #fff0;
  position: relative;
  overflow: hidden;
}

.slider-form input[type="range"]:active {
  cursor: grabbing;
}

.slider-form input[type="range"]:disabled {
  filter: grayscale();
  opacity: .3;
  cursor: not-allowed;
}

.slider-form input[type="range"], .slider-form input[type="range"]::-webkit-slider-runnable-track, .slider-form input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: var(--thumb-height);
  transition: all .1s;
}

.slider-form input[type="range"]::-webkit-slider-runnable-track, .slider-form input[type="range"]::-webkit-slider-thumb {
  position: relative;
}

.slider-form input[type="range"]::-webkit-slider-thumb {
  --thumb-radius: calc((var(--thumb-height) * .5)  - 1px);
  --clip-top: calc((var(--thumb-height)  - var(--track-height)) * .5 - .5px);
  --clip-bottom: calc(var(--thumb-height)  - var(--clip-top));
  --clip-further: calc(100% + 1px);
  --box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0 100vmax #000;
  width: var(--thumb-width, var(--thumb-height));
  background: linear-gradient(currentColor 0 0) scroll no-repeat left center / 50% calc(var(--track-height)  + 1px);
  box-shadow: var(--box-fill);
  filter: brightness();
  clip-path: polygon(100% -1px, var(--clip-edges) -1px, 0 var(--clip-top), -100vmax var(--clip-top), -100vmax var(--clip-bottom), 0 var(--clip-bottom), var(--clip-edges) 100%, var(--clip-further) var(--clip-further));
  background-color: currentColor;
  background-image: url("pause.82bbd465.svg"), none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto, auto;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  border: 2px solid #000;
}

.slider-form input[type="range"]:hover::-webkit-slider-thumb {
  filter: brightness(var(--brightness-hover));
  cursor: grab;
}

.slider-form input[type="range"]:active::-webkit-slider-thumb {
  filter: brightness(var(--brightness-down));
  cursor: grabbing;
}

.slider-form input[type="range"]::-webkit-slider-runnable-track {
  background: linear-gradient(var(--track-color) 0 0) scroll no-repeat center / 100% calc(var(--track-height)  + 1px);
}

.slider-form input[type="range"]:disabled::-webkit-slider-thumb {
  cursor: not-allowed;
}

.slider-form input[type="range"], .slider-form input[type="range"]::-moz-range-track, .slider-form input[type="range"]::-moz-range-thumb {
  appearance: none;
  height: var(--thumb-height);
  transition: all .1s;
}

.slider-form input[type="range"]::-moz-range-track, .slider-form input[type="range"]::-moz-range-thumb, .slider-form input[type="range"]::-moz-range-progress {
  background: #fff0;
}

.slider-form input[type="range"]::-moz-range-thumb {
  width: var(--thumb-width, var(--thumb-height));
  border-radius: var(--thumb-width, var(--thumb-height));
  cursor: grab;
  background: #151ccd;
  border: 0;
}

.slider-form input[type="range"]:active::-moz-range-thumb {
  cursor: grabbing;
}

.slider-form input[type="range"]::-moz-range-track {
  width: 100%;
  background: var(--track-color);
}

.slider-form input[type="range"]::-moz-range-progress {
  appearance: none;
  background: currentColor;
  transition-delay: 30ms;
}

.slider-form input[type="range"]::-moz-range-track, .slider-form input[type="range"]::-moz-range-progress {
  height: calc(var(--track-height)  + 1px);
  border-radius: var(--track-height);
}

.slider-form input[type="range"]::-moz-range-thumb, .slider-form input[type="range"]::-moz-range-progress {
  filter: brightness();
}

.slider-form input[type="range"]:hover::-moz-range-thumb, .slider-form input[type="range"]:hover::-moz-range-progress {
  filter: brightness(var(--brightness-hover));
}

.slider-form input[type="range"]:active::-moz-range-thumb, .slider-form input[type="range"]:active::-moz-range-progress {
  filter: brightness(var(--brightness-down));
}

.slider-form input[type="range"]:disabled::-moz-range-thumb {
  cursor: not-allowed;
}

.slider-form .money-range-label, .slider-form .month-range-label {
  color: #fff;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #151ccd;
  padding: 5px 14px;
  font: 700 20px / 30px Poppins, sans-serif;
}

@media (min-width: 1024px) {
  .slider-form .money-range-label, .slider-form .month-range-label {
    padding: 10px 22px;
  }

  .slider-form .money-range-label {
    font: 700 25px / 38px Poppins, sans-serif;
  }
}

.slider-form .form-submit {
  cursor: pointer;
  border: none;
  margin: 16px auto 0;
}

@media (min-width: 1024px) {
  .slider-form .form-submit {
    background-color: #151ccd;
    align-self: center;
    margin: 32px 0 0;
  }
}

.slider-form .calculator-wrapper {
  flex-wrap: nowrap;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .slider-form .calculator-wrapper {
    background-color: #fff;
    padding: 48px;
    box-shadow: 0 4px 14px #00000040;
  }
}

.slider-form .calculator-form {
  transition: all .15s ease-in-out;
  position: relative;
  left: 0;
}

.slider-form .calculator-result {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all .15s ease-in-out;
  display: flex;
  position: absolute;
  top: 0;
  left: 100%;
}

@media (min-width: 1024px) {
  .slider-form .calculator-result {
    justify-content: center;
  }
}

.slider-form .calculator-result h3 {
  margin: 0 0 16px;
  font: 700 24px / 36px Poppins, sans-serif;
}

@media (min-width: 1024px) {
  .slider-form .calculator-result h3 {
    margin: 0 0 32px;
    font: 700 44px / 56px Poppins, sans-serif;
  }
}

.slider-form .calculator-result p {
  color: #151ccd;
  margin: 0 0 32px;
  font: 700 38px / 42px Poppins, sans-serif;
}

@media (min-width: 1024px) {
  .slider-form .calculator-result p {
    font: 700 56px / 70px Poppins, sans-serif;
  }
}

.slider-form .calculator-result button {
  border: 0;
}

.slider-form .calculator-result button img {
  transform: rotate(90deg);
}

.slider-form .show-result .calculator-form {
  left: calc(-100% - 96px);
}

.slider-form .show-result .calculator-result {
  left: 0;
}

.recover-summ {
  padding: 69px 0 0;
  display: none;
}

.recover-summ.show {
  display: block;
}

@media (min-width: 1024px) {
  .recover-summ {
    padding: 129px 0 0;
  }

  .recover-summ .container {
    align-items: center;
  }

  .recover-summ .container:after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: #d4d4d4;
    margin: 111px 0 0;
  }
}

.recover-summ p {
  text-align: center;
  margin: 32px 0 21px;
  font: 500 18px / 28px Poppins, sans-serif;
}

@media (min-width: 1024px) {
  .recover-summ p {
    max-width: 482px;
    margin: 49px 0 21px;
    font: 500 24px / 40px Poppins, sans-serif;
  }
}

.recover-summ__value {
  color: #151ccd;
  margin: 0 0 32px;
  font: 700 48px / 42px Poppins, sans-serif;
}

@media (min-width: 1024px) {
  .recover-summ__value {
    margin: 0 0 111px;
    font: 700 56px / 70px Poppins, sans-serif;
  }
}

.how-it-works {
  padding: 39px 0 38px;
}

@media (min-width: 1024px) {
  .how-it-works {
    padding: 110px 0 105px;
  }

  .how-it-works .container {
    align-items: center;
  }
}

.how-it-works .sub-title {
  color: #000;
  text-align: center;
  margin: 13px 0 0;
  font: 400 18px / 28px Poppins, sans-serif;
}

@media (min-width: 1024px) {
  .how-it-works .sub-title {
    margin: 23px 0 0;
    font: 400 20px / 28px Poppins, sans-serif;
  }
}

.how-it-works .steps-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 27px;
  margin: 32px 0 60px;
  display: flex;
}

@media (min-width: 1024px) {
  .how-it-works .steps-wrapper {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: 63px 0 109px;
  }
}

.how-it-works .steps-wrapper .step {
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: flex;
}

@media (min-width: 1024px) {
  .how-it-works .steps-wrapper .step {
    flex-direction: column;
    gap: 7px;
  }
}

.how-it-works .steps-wrapper .step .img-wrapper {
  width: 88px;
  height: 88px;
  background-color: #f1f1f1;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.how-it-works .steps-wrapper .step .img-wrapper img {
  max-width: 90%;
}

.how-it-works .steps-wrapper .step p {
  max-width: 167px;
  color: #000;
  font: 600 14px / 20px Poppins, sans-serif;
}

@media (min-width: 414px) {
  .how-it-works .steps-wrapper .step p {
    max-width: 207px;
    font: 600 17px / 26px Poppins, sans-serif;
  }
}

@media (min-width: 1024px) {
  .how-it-works .steps-wrapper .step p {
    text-align: center;
  }
}

.how-it-works .steps-wrapper .step p a {
  color: #000;
  border-bottom: 3px solid #151ccd;
  padding: 0 0 4px;
  text-decoration: none;
}

.how-it-works .steps-wrapper .arrow-wrapper {
  display: none;
}

@media (min-width: 1024px) {
  .how-it-works .steps-wrapper .arrow-wrapper {
    width: calc(20vw - 120px);
    max-width: 197px;
    display: flex;
    position: absolute;
    left: 101px;
  }
}

.how-it-works .steps-wrapper .arrow {
  display: none;
}

@media (min-width: 1024px) {
  .how-it-works .steps-wrapper .arrow {
    height: 3px;
    width: 100%;
    background-color: #000;
    display: flex;
    position: relative;
  }
}

.how-it-works .steps-wrapper .arrow:before, .how-it-works .steps-wrapper .arrow:after {
  content: "";
  width: 16px;
  height: 3px;
  background-color: #000;
  border-radius: 10px;
  position: absolute;
}

.how-it-works .steps-wrapper .arrow:before {
  top: -5px;
  right: -4px;
  transform: rotate(45deg);
}

.how-it-works .steps-wrapper .arrow:after {
  top: 5px;
  right: -4px;
  transform: rotate(-45deg);
}

.how-it-works .card {
  max-width: 550px;
  background-color: #f1f1f1;
  border-radius: 20px;
}

@media (min-width: 1024px) {
  .how-it-works .card {
    max-width: unset;
    border-radius: 25px;
    display: flex;
  }
}

.how-it-works .card > img {
  width: 100%;
}

@media (min-width: 1024px) {
  .how-it-works .card > img {
    max-width: 500px;
  }
}

@media (min-width: 1290px) {
  .how-it-works .card > img {
    max-width: 623px;
  }
}

.how-it-works .card .card-content {
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  display: flex;
}

@media (min-width: 1024px) {
  .how-it-works .card .card-content {
    gap: 25px;
    padding: 40px;
  }
}

@media (min-width: 1290px) {
  .how-it-works .card .card-content {
    gap: 32px;
    padding: 63px 75px 52px 73px;
  }
}

.how-it-works .card .card-content h3 {
  color: #000;
  margin: 0;
  font: 700 18px / 30px Poppins, sans-serif;
}

@media (min-width: 414px) {
  .how-it-works .card .card-content h3 {
    font: 700 21px / 30px Poppins, sans-serif;
  }
}

@media (min-width: 1024px) {
  .how-it-works .card .card-content h3 {
    letter-spacing: 1px;
    font: 700 22px / 32px Poppins, sans-serif;
  }
}

@media (min-width: 1290px) {
  .how-it-works .card .card-content h3 {
    font: 700 28px / 42px Poppins, sans-serif;
  }
}

.how-it-works .card .card-content .btn {
  justify-content: center;
  align-items: center;
  gap: 0;
  padding: 10px 14px;
  font: 600 12px / 16px Poppins, sans-serif;
}

@media (min-width: 414px) {
  .how-it-works .card .card-content .btn {
    gap: 10px;
    padding: 16px 24px;
    font: 600 16px / 24px Poppins, sans-serif;
  }
}

.scan-module {
  background-color: #f1f1f1;
  padding: 25px 0 0;
  overflow-x: hidden;
}

@media (min-width: 1024px) {
  .scan-module {
    padding: 70px 0 0;
  }

  .scan-module .container {
    max-width: 1290px;
  }
}

.scan-module h3 {
  max-width: 550px;
  margin: 0 0 50px;
}

@media (min-width: 1024px) {
  .scan-module h3 {
    max-width: 750px;
    text-align: left;
    margin: 0 0 38px;
    line-height: 66px;
  }
}

.scan-module .image-container {
  flex-direction: column;
  display: flex;
  position: relative;
}

.scan-module .image-container .document-image {
  width: 153px;
  height: 215px;
  position: inherit;
  z-index: 1;
}

@media (min-width: 1024px) {
  .scan-module .image-container .document-image {
    width: 280px;
    height: 385px;
  }
}

.scan-module .image-container .shade {
  width: 612px;
  height: 586px;
  z-index: 1;
  position: absolute;
  top: -151px;
  left: 165px;
}

@media (min-width: 1290px) {
  .scan-module .image-container .shade {
    left: 245px;
  }
}

.scan-module .image-container .document-image-tr {
  width: 168px;
  height: 342px;
  z-index: 2;
  position: absolute;
  top: 110px;
  left: 95px;
}

@media (min-width: 1024px) {
  .scan-module .image-container .document-image-tr {
    width: 272px;
    height: 580px;
    top: -148px;
    left: 695px;
  }
}

@media (min-width: 1290px) {
  .scan-module .image-container .document-image-tr {
    top: -155px;
    left: 855px;
  }
}

.scan-module .image-container .document-image-m {
  width: 122px;
  height: 173px;
  z-index: 3;
  position: absolute;
  top: 180px;
  left: 118px;
}

@media (min-width: 1024px) {
  .scan-module .image-container .document-image-m {
    width: 208px;
    height: 293px;
    top: -60px;
    left: 728px;
  }
}

@media (min-width: 1290px) {
  .scan-module .image-container .document-image-m {
    left: 888px;
  }
}

.scan-module .image-container .phone {
  width: 291px;
  height: 420px;
  position: inherit;
  z-index: 4;
  margin: -120px 0 0;
  transform: translateX(70px);
}

@media (min-width: 1024px) {
  .scan-module .image-container .phone {
    width: 494px;
    height: 714px;
    transform: initial;
    margin: 0;
    position: absolute;
    top: -164px;
    left: 645px;
  }
}

@media (min-width: 1290px) {
  .scan-module .image-container .phone {
    left: 805px;
  }
}

.scan-module .image-container .phone-btn {
  color: #fff;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 5;
  padding: 10px 36px;
  font: 600 9px / 14px Poppins, sans-serif;
  position: absolute;
  top: 377px;
  left: 121px;
}

@media (min-width: 1024px) {
  .scan-module .image-container .phone-btn {
    padding: 16px 60px;
    font: 600 16px / 24px Poppins, sans-serif;
    top: 275px;
    left: 733px;
  }
}

@media (min-width: 1290px) {
  .scan-module .image-container .phone-btn {
    left: 893px;
  }
}

.scan-module .image-container p {
  color: #000;
  max-width: 283px;
  width: 100%;
  z-index: 2;
  margin: 0;
  font: 700 24px / 36px Poppins, sans-serif;
  position: absolute;
  top: 150px;
  left: 288px;
}

.scan-module .image-container .btn.display-desktop {
  margin: 41px 0 66px;
}

.banner-card {
  padding: 38px 0 76px;
}

@media (min-width: 1024px) {
  .banner-card {
    padding: 86px 0 88px;
  }

  .banner-card .container {
    max-width: 1310px;
  }
}

.banner-card .card {
  background-color: #151ccd;
  border-radius: 20px;
}

@media (min-width: 1024px) {
  .banner-card .card {
    width: 100%;
    border-radius: 25px;
    display: flex;
  }
}

.banner-card .card img {
  width: 100%;
}

@media (min-width: 1024px) {
  .banner-card .card img {
    max-width: 450px;
  }
}

@media (min-width: 1290px) {
  .banner-card .card img {
    max-width: 587px;
  }
}

.banner-card .card .card-content {
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  display: flex;
}

@media (min-width: 1024px) {
  .banner-card .card .card-content {
    gap: 18px;
    padding: 40px;
  }
}

@media (min-width: 1290px) {
  .banner-card .card .card-content {
    gap: 20px;
    padding: 63px 124px 44px 73px;
  }
}

.banner-card .card .card-content h3 {
  color: #fff;
  margin: 0;
  font: 700 20px / 30px Poppins, sans-serif;
}

@media (min-width: 1024px) {
  .banner-card .card .card-content h3 {
    font: 600 26px / 41px Poppins, sans-serif;
  }
}

@media (min-width: 1290px) {
  .banner-card .card .card-content h3 {
    font: 600 32px / 51px Poppins, sans-serif;
  }
}

.banner-card .card .card-content p {
  color: #fff;
  margin: 0;
  font: 500 16px / 28px Poppins, sans-serif;
}

@media (min-width: 1024px) {
  .banner-card .card .card-content p {
    margin: 0 0 12px;
    font: 500 18px / 27px Poppins, sans-serif;
  }
}

.banner-card .card .card-content .btn img {
  filter: brightness(0) saturate() invert(11%) sepia(56%) saturate(6427%) hue-rotate(241deg) brightness(113%) contrast(120%);
}

.clients-feedback {
  padding: 0 0 38px;
  position: relative;
}

@media (min-width: 1024px) {
  .clients-feedback {
    padding: 0 0 58px;
  }

  .clients-feedback .desktop-container {
    position: relative;
  }
}

.clients-feedback h3 {
  margin: 0 30px 42px;
}

@media (min-width: 1024px) {
  .clients-feedback h3 {
    width: 100%;
    margin: 0 0 82px;
  }
}

.clients-feedback .item {
  max-width: 90%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin: 0 auto;
  display: flex;
}

@media (min-width: 1024px) {
  .clients-feedback .item {
    max-width: 353px;
  }
}

.clients-feedback .item .image {
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  padding: 10px 0;
  position: relative;
}

.clients-feedback .item .image:after {
  content: "";
  width: 180px;
  height: 160px;
  z-index: -1;
  background-color: #151ccd;
  display: flex;
  position: absolute;
  top: 21px;
  left: -12px;
  transform: skewX(-3deg)rotate(2deg);
}

.clients-feedback .item .img-wrapper {
  width: 180px;
  height: 160px;
  overflow: hidden;
  transform: skewX(-3deg)rotate(2deg);
}

.clients-feedback .item .img-wrapper img {
  object-fit: cover;
  width: 120%;
  height: 120%;
  position: relative;
  top: 50%;
  left: 52%;
  transform: skewX(3deg)translate(-50%, -50%);
}

.clients-feedback .item img {
  max-width: 212px;
  position: relative;
}

.clients-feedback .item .text p {
  color: #000;
  text-align: center;
  max-width: 450px;
  margin: 0;
  font: 600 18px / 30px Poppins, sans-serif;
}

.clients-feedback .item .name p {
  color: #000;
  text-align: center;
  margin: 0;
  font: 400 15px / 28px Poppins, sans-serif;
}

.clients-feedback .item .customNextBtn {
  width: 40px;
  position: absolute;
  top: 50%;
  right: -60px;
}

.clients-feedback .customPrevBtn, .clients-feedback .customNextBtn {
  cursor: pointer;
  z-index: 11;
  width: 40px;
  height: 40px;
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  top: 36%;
  transform: translateY(-50%);
}

@media (min-width: 1024px) {
  .clients-feedback .customPrevBtn, .clients-feedback .customNextBtn {
    top: 57%;
  }
}

.clients-feedback .customPrevBtn {
  left: 20px;
}

@media (min-width: 1700px) {
  .clients-feedback .customPrevBtn {
    left: -20px;
  }
}

.clients-feedback .customNextBtn {
  right: 20px;
}

@media (min-width: 414px) {
  .clients-feedback .customNextBtn {
    right: 15%;
  }
}

@media (min-width: 576px) {
  .clients-feedback .customNextBtn {
    right: 20%;
  }
}

@media (min-width: 1024px) {
  .clients-feedback .customNextBtn {
    right: 20px;
  }
}

@media (min-width: 1700px) {
  .clients-feedback .customNextBtn {
    right: -20px;
  }
}

.clients-feedback .owl-theme .owl-nav.disabled + .owl-dots {
  margin: 0;
}

.clients-feedback .owl-theme .owl-dots .owl-dot span {
  width: 32px;
  height: 4px;
  background-color: #000;
  border-radius: 0;
}

.clients-feedback .owl-theme .owl-dots:focus .owl-dot span {
  background-color: #000;
}

.clients-feedback .owl-theme .owl-dots .owl-dot.active span, .clients-feedback .owl-theme .owl-dots .owl-dot:hover span {
  background-color: #151ccd;
}

.video-feedback {
  background-color: #f1f1f1;
  padding: 34px 0 48px;
  position: relative;
}

@media (min-width: 1024px) {
  .video-feedback {
    padding: 71px 0 89px;
  }
}

.video-feedback .desktop-container {
  position: relative;
}

.video-feedback h3 {
  margin: 0 0 48px;
}

@media (min-width: 1024px) {
  .video-feedback h3 {
    width: 100%;
    margin: 0 0 64px;
    font: 700 30px / 45px Poppins, sans-serif;
  }
}

.video-feedback .item {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding-inline: 15px;
  display: flex;
  transform: translateX(30px);
}

@media (min-width: 414px) {
  .video-feedback .item {
    padding-inline: 0;
    transform: translateX(0);
  }
}

@media (min-width: 1024px) {
  .video-feedback .item {
    gap: 30px;
    margin-left: 60px;
  }
}

.video-feedback .item .video {
  width: 300px;
  height: 168px;
  position: relative;
  overflow: hidden;
  box-shadow: -9px 9px #151ccd;
}

@media (min-width: 1024px) {
  .video-feedback .item .video {
    width: 350px;
    height: 218px;
  }
}

@media (min-width: 1290px) {
  .video-feedback .item .video {
    width: 480px;
    height: 270px;
  }
}

.video-feedback .item .video .video-preview {
  filter: brightness(50%);
  z-index: 1;
  position: inherit;
  min-height: 100%;
  min-width: 100%;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
}

.video-feedback .item .video .play-video {
  width: 46px;
  height: 46px;
  z-index: 2;
  cursor: pointer;
  display: flex;
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 1024px) {
  .video-feedback .item .video .play-video {
    width: 60px;
    height: 60px;
  }
}

.video-feedback .item .video .video-overlay {
  width: 100%;
  height: 100%;
  opacity: .25;
  cursor: pointer;
  transform: initial;
  background-color: #151ccd;
  position: absolute;
  top: 0;
  left: 0;
}

.video-feedback .item .text p {
  color: #000;
  text-align: center;
  margin: 0;
  font: 600 18px / 30px Poppins, sans-serif;
}

.video-feedback .item .name p {
  color: #000;
  text-align: center;
  margin: 0;
  font: 400 15px / 28px Poppins, sans-serif;
}

.video-feedback .item .text p, .video-feedback .item .name p {
  max-width: 353px;
}

.video-feedback .video iframe {
  position: absolute;
  top: 0;
}

.video-feedback .customVideoPrevBtn, .video-feedback .customVideoNextBtn {
  cursor: pointer;
  z-index: 11;
  width: 40px;
  height: 40px;
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.video-feedback .customVideoPrevBtn {
  left: 20px;
}

@media (min-width: 1700px) {
  .video-feedback .customVideoPrevBtn {
    left: -20px;
  }
}

.video-feedback .customVideoNextBtn {
  right: 20px;
}

@media (min-width: 1700px) {
  .video-feedback .customVideoNextBtn {
    right: -20px;
  }
}

.video-feedback .carousel-fixed-width {
  width: 300px !important;
}

.video-feedback .owl-theme .owl-nav.disabled + .owl-dots {
  margin: 60px 0 0;
}

.video-feedback .owl-theme .owl-dots .owl-dot span {
  width: 32px;
  height: 4px;
  background-color: #000;
  border-radius: 0;
}

.video-feedback .owl-theme .owl-dots:focus .owl-dot span {
  background-color: #000;
}

.video-feedback .owl-theme .owl-dots .owl-dot.active span, .video-feedback .owl-theme .owl-dots .owl-dot:hover span {
  background-color: #151ccd;
}

.video-feedback .owl-stage {
  left: -30px;
}

.services-cost {
  padding: 48px 0;
}

@media (min-width: 1024px) {
  .services-cost {
    padding: 66px 0 44px;
  }
}

.services-cost h3 {
  margin: 0 0 31px;
}

@media (min-width: 1024px) {
  .services-cost h3 {
    width: 100%;
    margin: 0 0 62px;
  }
}

.services-cost .plan-wrapper {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  margin: 0 0 59px;
  display: flex;
}

@media (min-width: 1024px) {
  .services-cost .plan-wrapper {
    flex-direction: row;
    align-items: flex-start;
    margin: 0 0 80px;
  }
}

@media (min-width: 1290px) {
  .services-cost .plan-wrapper {
    gap: 63px;
  }
}

.services-cost .plan-wrapper .plan {
  width: 100%;
  max-width: 400px;
}

.services-cost .plan-wrapper .plan .header {
  background-color: #000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 14px;
  display: flex;
}

@media (min-width: 1024px) {
  .services-cost .plan-wrapper .plan .header {
    padding: 16px;
  }
}

.services-cost .plan-wrapper .plan .header h4 {
  color: #fff;
  text-align: center;
  margin: 0;
  font: 600 20px / 30px Poppins, sans-serif;
}

@media (min-width: 1024px) {
  .services-cost .plan-wrapper .plan .header h4 {
    font: 600 26px / 39px Poppins, sans-serif;
  }
}

.services-cost .plan-wrapper .plan .header p {
  color: #fff;
  text-align: center;
  margin: 0;
  font: 400 14px / 21px Poppins, sans-serif;
}

.services-cost .plan-wrapper .plan .content {
  background-color: #f0f0f0;
  flex-direction: column;
  gap: 21px;
  padding: 21px;
  display: flex;
}

@media (min-width: 1290px) {
  .services-cost .plan-wrapper .plan .content {
    gap: 24px;
    padding: 24px;
  }
}

.services-cost .plan-wrapper .plan .content ul {
  flex-direction: column;
  gap: 21px;
  margin: 0;
  display: flex;
}

@media (min-width: 1024px) {
  .services-cost .plan-wrapper .plan .content ul {
    padding: 0 0 0 20px;
  }
}

@media (min-width: 1290px) {
  .services-cost .plan-wrapper .plan .content ul {
    gap: 24px;
  }
}

.services-cost .plan-wrapper .plan .content li {
  color: #000;
  font: 600 15px / 26px Poppins, sans-serif;
}

@media (min-width: 1024px) {
  .services-cost .plan-wrapper .plan .content li {
    font: 600 18px / 30px Poppins, sans-serif;
  }
}

.services-cost .plan-wrapper .plan .content .plan-price {
  color: #000;
  text-align: center;
  font: 800 26px / 26px Poppins, sans-serif;
}

@media (min-width: 1024px) {
  .services-cost .plan-wrapper .plan .content .plan-price {
    font: 800 30px / 30px Poppins, sans-serif;
  }
}

.services-cost .plan-wrapper .plan .content .btn {
  justify-content: center;
  font: 600 16px / 24px Poppins, sans-serif;
}

@media (min-width: 1024px) {
  .services-cost .plan-wrapper .plan .content .btn {
    width: 100%;
    font: 600 18px / 27px Poppins, sans-serif;
  }
}

.services-cost .plan-wrapper .plan.premium {
  position: relative;
}

.services-cost .plan-wrapper .plan.premium:after {
  content: "";
  width: 100%;
  height: 93%;
  z-index: -1;
  background-color: #c0ff00;
  position: absolute;
  bottom: -9px;
  left: -9px;
}

.services-cost .plan-wrapper .plan.premium .header {
  background-color: #151ccd;
}

@media (min-width: 1024px) {
  .services-cost .plan-wrapper .plan.premium .header h4 {
    font: 600 36px / 54px Poppins, sans-serif;
  }

  .services-cost .plan-wrapper .plan.premium .content .plan-price {
    font: 800 36px / 30px Poppins, sans-serif;
  }

  .services-cost .plan-wrapper .plan.premium .content .btn {
    font: 600 20px / 30px Poppins, sans-serif;
  }
}

.services-cost .plan-wrapper .plan.vip {
  position: relative;
}

.services-cost .plan-wrapper .plan.vip:after {
  content: "";
  width: 100%;
  height: 93%;
  z-index: -1;
  background-color: #000;
  position: absolute;
  bottom: -9px;
  left: -9px;
}

.services-cost .plan-wrapper .plan.vip .header {
  background-color: #ffd600;
}

@media (min-width: 1024px) {
  .services-cost .plan-wrapper .plan.vip .header h4 {
    font: 700 36px / 54px Poppins, sans-serif;
  }
}

.services-cost .plan-wrapper .plan.vip .header h4, .services-cost .plan-wrapper .plan.vip .header p {
  color: #000;
}

@media (min-width: 1024px) {
  .services-cost .plan-wrapper .plan.vip .content .plan-price {
    font: 800 36px / 30px Poppins, sans-serif;
  }

  .services-cost .plan-wrapper .plan.vip .content .btn {
    font: 700 20px / 30px Poppins, sans-serif;
  }
}

.services-cost .plan-wrapper .plan.vip .content .btn img {
  filter: brightness(0);
}

.services-cost .card {
  max-width: 550px;
  background-color: #f1f1f1;
  border-radius: 20px;
}

@media (min-width: 1024px) {
  .services-cost .card {
    width: 100%;
    max-width: unset;
    border-radius: 25px;
    justify-content: space-between;
    display: flex;
  }
}

.services-cost .card > img {
  width: 100%;
}

@media (min-width: 1024px) {
  .services-cost .card > img {
    max-width: 550px;
  }
}

@media (min-width: 1290px) {
  .services-cost .card > img {
    max-width: 693px;
  }
}

.services-cost .card .card-content {
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  display: flex;
}

@media (min-width: 1024px) {
  .services-cost .card .card-content {
    gap: 20px;
    padding: 40px;
  }
}

@media (min-width: 1290px) {
  .services-cost .card .card-content {
    justify-content: space-around;
    gap: 24px;
    padding: 68px 101px 71px 86px;
  }
}

.services-cost .card .card-content h3 {
  columns: #000;
  text-align: left;
  margin: 0;
  font: 700 20px / 30px Poppins, sans-serif;
}

@media (min-width: 1024px) {
  .services-cost .card .card-content h3 {
    font: 700 28px / 47px Poppins, sans-serif;
  }
}

@media (min-width: 1290px) {
  .services-cost .card .card-content h3 {
    font: 700 36px / 67px Poppins, sans-serif;
  }
}

.services-cost .card .card-content p {
  color: #000;
  text-emphasis: left;
  margin: 0;
  font: 500 16px / 28px Poppins, sans-serif;
}

@media (min-width: 1024px) {
  .services-cost .card .card-content p {
    font: 500 18px / 29px Poppins, sans-serif;
  }
}

@media (min-width: 1290px) {
  .services-cost .card .card-content p {
    font: 500 20px / 30px Poppins, sans-serif;
  }
}

.services-cost .card .card-content .btn {
  white-space: nowrap;
  padding: 15px 40px;
  font: 700 16px / 24px Poppins, sans-serif;
}

@media (min-width: 1024px) {
  .services-cost .card .card-content .btn {
    padding: 19px 40px;
    font: 700 20px / 30px Poppins, sans-serif;
  }
}

.faq {
  padding: 0 0 48px;
}

@media (min-width: 1024px) {
  .faq {
    padding: 0 0 82px;
  }
}

.faq .title-2 {
  width: 100%;
  text-align: left;
}

@media (min-width: 1024px) {
  .faq .title-2 {
    text-align: center;
  }
}

.faq .faq-wrapper {
  flex-direction: column;
  gap: 15px;
  margin: 32px 0 0;
  display: flex;
}

@media (min-width: 1024px) {
  .faq .faq-wrapper {
    max-width: 992px;
    margin: 32px auto 0;
  }
}

.faq .item {
  background-color: #f1f1f1;
  border-radius: 10px;
  flex-direction: column;
  display: flex;
}

.faq .item:not(.active) .question {
  padding: 16px;
}

@media (min-width: 1024px) {
  .faq .item:not(.active) .question {
    padding: 19px 24px;
  }
}

.faq .item:not(.active) .question img {
  transform: rotate(180deg);
}

.faq .item:not(.active) .answer {
  height: 0;
  margin: 0;
  padding: 0;
}

.faq .question {
  cursor: pointer;
  color: #000;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  padding: 16px 16px 0;
  font: 600 16px / 24px Poppins, sans-serif;
  display: flex;
}

@media (min-width: 1024px) {
  .faq .question {
    align-items: center;
    padding: 19px 24px 0;
    font: 600 18px / 27px Poppins, sans-serif;
  }
}

.faq .answer {
  height: -moz-fit-content;
  height: fit-content;
  margin: 10px 0 0;
  padding: 0 16px 16px;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .faq .answer {
    margin: 12px 0 0;
    padding: 0 64px 19px 24px;
    font: 400 16px / 27px Poppins, sans-serif;
  }
}

.faq .faq-link {
  color: initial;
  border-bottom: 1px solid #151ccd;
  text-decoration: none;
}

footer {
  width: 100%;
  background-color: #f1f1f1;
  padding: 47px 0 26px;
}

@media (min-width: 1024px) {
  footer {
    padding: 55px 0 50px;
  }
}

footer .container {
  padding: 0 22px 0 35px;
}

footer .top {
  width: 100%;
}

@media (min-width: 1024px) {
  footer .top {
    justify-content: space-between;
    align-items: center;
    margin: 0 0 16px;
    display: flex;
  }
}

footer .top .title-1 {
  text-align: left;
  margin: 0 0 18px;
}

@media (min-width: 1024px) {
  footer .top .title-1 {
    font: 700 24px / 36px Poppins, sans-serif;
  }

  footer .top .btn-wrapper {
    gap: 20px;
    display: flex;
  }
}

@media (min-width: 1290px) {
  footer .top .btn-wrapper {
    gap: 40px;
  }
}

footer .top .btn {
  white-space: nowrap;
  margin: 0 0 32px;
}

@media (min-width: 1024px) {
  footer .top .btn {
    margin: 0;
  }
}

footer .bottom {
  width: 100%;
  flex-direction: column;
  padding: 7px 0 0;
  display: flex;
}

@media (min-width: 1024px) {
  footer .bottom {
    flex-flow: wrap;
    justify-content: space-between;
    align-items: flex-end;
    row-gap: 30px;
    margin: 0 0 32px;
    padding: 0;
  }
}

footer .bottom .logo-wrapper {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  margin: 0 0 20px;
  display: flex;
}

@media (min-width: 1024px) {
  footer .bottom .logo-wrapper {
    gap: 29px;
    margin: 0;
  }
}

footer .bottom .logo-wrapper img {
  height: 28px;
}

footer .bottom .logo-wrapper .copyright {
  color: #000;
  margin: 0;
  font: 500 14px / 21px Poppins, sans-serif;
}

footer .bottom .static-pages {
  margin: 0 0 20px;
}

@media (min-width: 1024px) {
  footer .bottom .static-pages {
    margin: 0;
  }
}

footer .bottom .static-pages ul {
  flex-direction: column;
  gap: 24px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

@media (min-width: 1024px) {
  footer .bottom .static-pages ul {
    flex-direction: row;
    gap: 32px;
  }
}

footer .bottom .static-pages li a {
  color: #000;
  font: 500 14px / 21px Poppins, sans-serif;
  text-decoration: underline;
}

footer .bottom .payment-methods {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 22px;
  padding: 20px 0 0;
  display: flex;
}

@media (min-width: 1024px) {
  footer .bottom .payment-methods {
    gap: 32px;
    padding: 0;
  }
}

footer .bottom .payment-methods img:nth-child(1) {
  max-height: 26px;
}

@media (min-width: 1024px) {
  footer .bottom .payment-methods img:nth-child(1) {
    max-height: 22px;
  }
}

footer .bottom .payment-methods img:nth-child(2) {
  max-height: 48px;
}

@media (min-width: 1024px) {
  footer .bottom .payment-methods img:nth-child(2) {
    max-height: 36px;
  }
}

footer .bottom .payment-methods img:nth-child(3) {
  max-height: 36px;
}

@media (min-width: 1024px) {
  footer .bottom .payment-methods img:nth-child(3) {
    max-height: 24px;
  }
}

footer .sub-footer {
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 27px;
  margin: 35px 0 0;
  display: flex;
}

@media (min-width: 1024px) {
  footer .sub-footer {
    gap: 24px;
    margin: 0;
  }
}

footer .sub-footer .follow-us {
  color: #000;
  font: 500 14px / 21px Poppins, sans-serif;
}

footer .sub-footer .social-icons {
  justify-content: flex-start;
  align-items: center;
  gap: 36px;
  display: flex;
}

@media (min-width: 1024px) {
  footer .sub-footer .social-icons {
    gap: 32px;
  }
}

footer .sub-footer .social-icons a {
  text-decoration: none;
  display: flex;
}

footer .sub-footer .social-icons a:last-child img {
  max-height: 25px;
  height: 25px;
}

@media (min-width: 1024px) {
  footer .sub-footer .social-icons a:last-child img {
    max-height: 30px;
    height: 30px;
  }
}

footer .sub-footer .social-icons img {
  max-height: 27px;
  width: auto;
  height: 27px;
}

@media (min-width: 1024px) {
  footer .sub-footer .social-icons img {
    max-height: 32px;
    height: 32px;
  }
}

/*# sourceMappingURL=index.7b5cff37.css.map */
