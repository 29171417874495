.faq {
  padding: 0 0 48px;

  @include md {
    padding: 0 0 82px;
  }

  .title-2 {
    width: 100%;
    text-align: left;

    @include md {
      text-align: center;
    }
  }

  .faq-wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 32px 0 0;

    @include md {
      max-width: 992px;
      margin: 32px auto 0;
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    background-color: #f1f1f1;
    border-radius: 10px;

    &:not(.active) {
      .question {
        padding: 16px;

        @include md {
          padding: 19px 24px;
        }

        img {
          transform: rotate(180deg);
        }
      }

      .answer {
        height: 0;
        margin: 0;
        padding: 0;
      }
    }
  }

  .question {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
    padding: 16px 16px 0;
    cursor: pointer;

    font: 600 16px/24px $poppins;
    color: $color-black;

    @include md {
      align-items: center;
      padding: 19px 24px 0;
      font: 600 18px/27px $poppins;
    }
  }

  .answer {
    margin: 10px 0 0;
    padding: 0 16px 16px;
    height: fit-content;
    overflow: hidden;

    @include md {
      margin: 12px 0 0;
      padding: 0 64px 19px 24px;
      font: 400 16px/27px $poppins;
    }
  }

  .faq-link {
    text-decoration: none;
    color: initial;
    border-bottom: 1px solid $color-primary;
  }
}
