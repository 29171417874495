.scan-module {
  background-color: #f1f1f1;
  padding: 25px 0 0;
  overflow-x: hidden;

  @include md {
    padding: 70px 0 0;
  }

  .container {
    @include md {
      max-width: 1290px;
    }
  }

  h3 {
    margin: 0 0 50px;
    max-width: 550px;

    @include md {
      max-width: 750px;
      text-align: left;
      margin: 0 0 38px;
      line-height: 66px;
    }
  }

  .image-container {
    position: relative;
    display: flex;
    flex-direction: column;

    .document-image {
      width: 153px;
      height: 215px;
      position: inherit;
      z-index: 1;

      @include md {
        width: 280px;
        height: 385px;
      }
    }

    .shade {
      width: 612px;
      height: 586px;
      z-index: 1;
      position: absolute;
      top: -151px;
      left: 165px;

      @include lg {
        left: 245px;
      }
    }

    .document-image-tr {
      width: 168px;
      height: 342px;
      position: absolute;

      top: 110px;
      left: 95px;
      z-index: 2;

      @include md {
        width: 272px;
        height: 580px;

        top: -148px;
        left: 695px;
      }

      @include lg {
        top: -155px;
        left: 855px;
      }
    }

    .document-image-m {
      width: 122px;
      height: 173px;
      position: absolute;
      top: 180px;
      left: 118px;
      z-index: 3;

      @include md {
        width: 208px;
        height: 293px;
        top: -60px;
        left: 728px;
      }

      @include lg {
        left: 888px;
      }
    }

    .phone {
      width: 291px;
      height: 420px;
      margin: -120px 0 0;
      transform: translateX(70px);
      position: inherit;
      z-index: 4;

      @include md {
        width: 494px;
        height: 714px;
        margin: 0px;

        position: absolute;
        top: -164px;
        left: 645px;

        transform: initial;
      }

      @include lg {
        left: 805px;
      }
    }

    .phone-btn {
      font: 600 9px/14px $poppins;
      color: $color-white;

      width: fit-content;
      padding: 10px 36px;

      position: absolute;
      top: 377px;
      left: 121px;

      z-index: 5;

      @include md {
        font: 600 16px / 24px Poppins, sans-serif;
        padding: 16px 60px;
        top: 275px;
        left: 733px;
      }

      @include lg {
        left: 893px;
      }
    }

    p {
      font: 700 24px/36px $poppins;
      color: $color-black;

      max-width: 283px;
      width: 100%;
      margin: 0;

      position: absolute;
      left: 288px;
      top: 150px;

      z-index: 2;
    }

    .btn.display-desktop {
      margin: 41px 0 66px;
    }
  }
}
