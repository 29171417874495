.banner-card {
  padding: 38px 0 76px;

  @include md {
    padding: 86px 0 88px;
  }

  .container {
    @include md {
      max-width: 1310px;
    }
  }

  .card {
    border-radius: 20px;
    background-color: $color-primary;

    @include md {
      display: flex;
      width: 100%;
      border-radius: 25px;
    }

    img {
      width: 100%;

      @include md {
        max-width: 450px;
      }

      @include lg {
        max-width: 587px;
      }
    }

    .card-content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 24px;

      @include md {
        padding: 40px;
        gap: 18px;
      }

      @include lg {
        padding: 63px 124px 44px 73px;
        gap: 20px;
      }

      h3 {
        font: 700 20px/30px $poppins;
        color: $color-white;
        margin: 0;

        @include md {
          font: 600 26px/41px $poppins;
        }

        @include lg {
          font: 600 32px/51px $poppins;
        }
      }

      p {
        font: 500 16px/28px $poppins;
        color: $color-white;
        margin: 0;

        @include md {
          font: 500 18px/27px $poppins;
          margin: 0 0 12px;
        }
      }

      .btn img {
        filter: brightness(0) saturate(100%) invert(11%) sepia(56%)
          saturate(6427%) hue-rotate(241deg) brightness(113%) contrast(120%);
      }
    }
  }
}
