.how-it-works {
  padding: 39px 0 38px;

  @include md {
    padding: 110px 0 105px;
  }

  .container {
    @include md {
      align-items: center;
    }
  }

  .sub-title {
    font: 400 18px/28px $poppins;
    color: $color-black;
    text-align: center;
    margin: 13px 0 0;

    @include md {
      font: 400 20px/28px $poppins;
      margin: 23px 0 0;
    }
  }

  .steps-wrapper {
    margin: 32px 0 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 27px;

    @include md {
      margin: 63px 0 109px;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      align-items: flex-start;
    }

    .step {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;

      @include md {
        flex-direction: column;
        gap: 7px;
      }

      .img-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f1f1f1;
        border-radius: 100%;
        width: 88px;
        height: 88px;
        position: relative;

        img {
          max-width: 90%;
        }
      }

      p {
        max-width: 167px;
        font: 600 14px / 20px Poppins, sans-serif;
        color: $color-black;

        @include xxs {
          max-width: 207px;
          font: 600 17px/26px $poppins;
        }

        @include md {
          text-align: center;
        }

        a {
          text-decoration: none;
          color: $color-black;
          border-bottom: 3px solid $color-primary;
          padding: 0 0 4px;
        }
      }
    }

    .arrow-wrapper {
      display: none;

      @include md {
        width: calc(20vw - 120px);
        max-width: 197px;
        display: flex;
        position: absolute;
        left: 101px;
      }
    }

    .arrow {
      display: none;

      @include md {
        display: flex;
        position: relative;
        height: 3px;
        width: 100%;
        background-color: $color-black;
      }

      &::before,
      &::after {
        content: "";
        width: 16px;
        height: 3px;
        background-color: $color-black;
        position: absolute;
        border-radius: 10px;
      }

      &::before {
        top: -5px;
        right: -4px;
        transform: rotate(45deg);
      }

      &::after {
        top: 5px;
        right: -4px;
        transform: rotate(-45deg);
      }
    }
  }

  .card {
    background-color: #f1f1f1;
    border-radius: 20px;
    max-width: 550px;

    @include md {
      display: flex;
      border-radius: 25px;
      max-width: unset;
    }

    > img {
      width: 100%;

      @include md {
        max-width: 500px;
      }

      @include lg {
        max-width: 623px;
      }
    }

    .card-content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 24px;

      @include md {
        padding: 40px;
        gap: 25px;
      }

      @include lg {
        padding: 63px 75px 52px 73px;
        gap: 32px;
      }

      h3 {
        font: 700 18px/30px $poppins;
        color: $color-black;
        margin: 0;

        @include xxs {
          font: 700 21px/30px $poppins;
        }

        @include md {
          font: 700 22px/32px $poppins;
          letter-spacing: 1px;
        }

        @include lg {
          font: 700 28px/42px $poppins;
        }
      }

      .btn {
        font: 600 12px/16px $poppins;
        gap: 0;
        padding: 10px 14px;
        align-items: center;
        justify-content: center;

        @include xxs {
          font: 600 16px/24px $poppins;
          gap: 10px;
          padding: 16px 24px;
        }
      }
    }
  }
}
