*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  margin: 0;
  padding: 0;

  font-family: $poppins;
  overflow-x: hidden;

  &:has(.nav-list.open) {
    .top-nav .nav-container {
      position: fixed;
      top: 0;
    }

    main {
      margin-top: 99px;
    }
  }
}

main {
  width: 100%;
}

.display-mobile {
  display: flex !important;

  @include md {
    display: none !important;
  }
}

.display-desktop {
  display: none !important;

  @include md {
    display: flex !important;
  }
}

.display-desktop-lg {
  display: none !important;

  @include lg {
    display: flex !important;
  }
}

.display-none {
  display: none !important;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 28px 0 29px;

  @include md {
    align-items: flex-start;
    max-width: 1564px;
    margin: 0 auto;
    padding: 0 30px;
  }
}

.desktop-container {
  @include md {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    max-width: 1564px;
    margin: 0 auto;
    padding: 0 30px;
  }
}

.title-1 {
  font: 700 24px/36px $poppins;
  color: $color-black;
  text-align: center;
  margin: 0;

  @include md {
    font: 700 44px/56px $poppins;
  }
}

.title-2 {
  font: 700 28px/42px $poppins;
  color: $color-black;
  text-align: center;
  margin: 0;

  @include md {
    font: 700 44px/66px $poppins;
  }
}
