@import "./breakpoints";
@import "./mixins";
@import "./variables";
@import "./global";
@import "./top-nav";
@import "./buttons";
@import "./top-banner";
@import "./slider-form";
@import "./recover-summ";
@import "./how-it-works";
@import "./scan-module";
@import "./banner-card";
@import "./clients-feedback";
@import "./video-feedback";
@import "./services-cost";
@import "./faq";
@import "./footer";
