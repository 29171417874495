.top-nav {
  display: flex;
  width: 100%;
  z-index: 11;

  @include md {
    justify-content: space-between;
    align-items: center;
    padding: 17px 0 19px 0;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.18);
  }

  .container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;

    @include md {
      padding: 0 30px;
      gap: 30px;
    }
  }

  .nav-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px 40px 18px 25px;
    background-color: #fff;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.18);
    z-index: 10;

    @include md {
      width: fit-content;
      box-shadow: unset;
      padding: 0;
    }
  }

  .logo {
    text-decoration: none;
  }

  .menu-icon {
    display: flex;
  }

  .menu-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include md {
      gap: 30px;
    }

    @include xl {
      gap: 50px;
    }
  }

  .nav-list {
    list-style: none;
    background-color: #fff;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.18);

    position: fixed;
    top: -200vh;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    height: 100vh;
    height: 100dvh;
    margin: 0;
    padding: 112px 45px 0 25px;

    transition: 0.15s ease-in-out;
    z-index: 1;
    overflow: auto;

    @include md {
      flex-direction: row;
      width: fit-content;
      height: fit-content;
      padding: 0;
      gap: 18px;

      position: initial;
      box-shadow: unset;
      overflow: initial;
    }

    @include xl {
      gap: 28px;
    }

    &.open {
      top: 0;
    }

    li {
      @include md {
        padding: 0 5px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #151ccd;

        @include md {
          border: none;
        }
      }

      a {
        text-decoration: none;
        font: 700 19px/76px $poppins;
        color: #000;
        white-space: nowrap;
        cursor: pointer;

        @include md {
          font: 500 16px/24px $poppins;
          position: relative;
        }

        &::after {
          @include md {
            content: "";

            width: 0;
            height: 4px;
            background-color: #151ccd;

            position: absolute;
            left: 0;
            right: 0;
            bottom: -9px;

            transition: width 0.15s ease-in-out, left 0.15s ease-in-out;
          }
        }

        &.tel {
          font-weight: 700;

          @include md {
            display: none;
          }

          @include lg {
            display: initial;
          }
        }

        &.active {
          font-weight: 700;

          &::after {
            @include md {
              left: 0;
              width: 100%;
            }
          }
        }
      }
    }
  }

  .btn {
    width: fit-content;
    font: 600 16px/24px $poppins;
    white-space: nowrap;
  }

  .menu-button {
    display: none;

    @include xxl {
      display: flex;
    }
  }
}
