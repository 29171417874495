@mixin xxs {
  @media (min-width: $xxsBreakpoint) {
    @content;
  }
}

@mixin xs {
  @media (min-width: $xsBreakpoint) {
    @content;
  }
}

@mixin max-xs {
  @media (max-width: $xsBreakpoint) {
    @content;
  }
}

@mixin sm {
  @media (min-width: $smBreakpoint) {
    @content;
  }
}

@mixin md {
  @media (min-width: $mdBreakpoint) {
    @content;
  }
}

@mixin lg {
  @media (min-width: $lgBreakpoint) {
    @content;
  }
}

@mixin xl {
  @media (min-width: $xlBreakpoint) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: $xxlBreakpoint) {
    @content;
  }
}

@mixin custom($customBreakpoint) {
  @media (min-width: $customBreakpoint) {
    @content;
  }
}
